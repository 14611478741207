import { MagicUseCase } from '~/use-cases/magicUseCase';

export class RemoveRentalApplicationFileUseCase extends MagicUseCase {
  protected async runLogic(fileId: string) {
    const rentalApplication = this.getState().user.rentalApplication.application;
    if (!rentalApplication) {
      return;
    }
    const files = rentalApplication.newFiles;
    if (!files) {
      return;
    }
    rentalApplication.newFiles = files.filter((file) => file.id !== fileId);
  }
}
